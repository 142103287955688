<template>
  <div class="brandinfo">
    <div class="brandinfo_center">
      <header>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/brand' }">党建品牌</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/brand' ,query:{text:showtitle} }">{{showtitle}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{showtext}}</el-breadcrumb-item>
        </el-breadcrumb>
      </header>
      <main>
        <!-- 学区工作宣传 -->
        <gone v-if="showtitle=='学区工作宣传'&&showid==1"></gone>
        <gtwo v-if="showtitle=='学区工作宣传'&&showid==2"></gtwo>
        <gthree v-if="showtitle=='学区工作宣传'&&showid==3"></gthree>
        <gfour v-if="showtitle=='学区工作宣传'&&showid==4"></gfour>
        <gfive v-if="showtitle=='学区工作宣传'&&showid==5"></gfive>
        <!-- 支部党建新闻 -->
        <sone v-if="showtitle=='支部党建新闻'&&showid==1"></sone>
        <stwo v-if="showtitle=='支部党建新闻'&&showid==2"></stwo>
        <sthree v-if="showtitle=='支部党建新闻'&&showid==3"></sthree>
        <sfour v-if="showtitle=='支部党建新闻'&&showid==4"></sfour>
        <!-- <sfive v-if="showtitle=='支部党建新闻'&&showid==5"></sfive> -->
      </main>
    </div>
  </div>
</template>
<script>
import gone from "./xuequ/one.vue";
import gtwo from "./xuequ/two.vue";
import gthree from "./xuequ/three.vue";
import gfour from "./xuequ/four.vue";
import gfive from "./xuequ/five.vue";

import sone from "./zhibu/one.vue";
import stwo from "./zhibu/two.vue";
import sthree from "./zhibu/three.vue";
import sfour from "./zhibu/four.vue";
// import sfive from "./zhibu/five.vue";
export default {
  components: {
    gone,
    gtwo,
    gthree,
    gfour,
    gfive,
    sone,
    stwo,
    sthree,
    sfour,
    // sfive,
  },
  data() {
    return {
      showid: null,
      showtitle: null,
      showtext: null,
    };
  },
  created() {
    console.log(this.$route.query, "路由");
    this.showid = this.$route.query.id;
    this.showtitle = this.$route.query.title;
    this.showtext = this.$route.query.text;
  },
};
</script>

<style lang="scss" scoped>
.brandinfo {
  .brandinfo_center {
    width: 1200px;
    margin: 0 auto;
    header {
      padding: 15px 0;
    }
  }
}
</style>